.main {
  width: 100%;
  float: left;
  margin: auto;
  padding: 30px;
  background-color: #f7f8fb;
  
}

.left-panel {
  max-height: calc(100vh - 70px);
  height: 100%;
  overflow: auto;
  width: 190px;
  background-color: #3bc1d7;
  color: #ffffff;
  float: left;
  position: fixed;
  left: 0;
  top: 85px;
  bottom: 0px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

.right-panel {
  /* min-height: 127vh; */
  /* margin-left: 190px; */
  position: relative;
  top: 85px;
  background-color: white;
}
.right-panel:after {
  content: '';
  clear: both;
  display: table;
}

.right-panel-full {
  min-height: calc(100vh - 170px);
  
}
