.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiTableCell-head {
  white-space: nowrap !important;
}

.wh-spc {
  white-space: nowrap;
  padding-left: 7px !important;
}

.date-pick-wrp .MuiFormControl-root {
  width: 100%;
}

.fetch-input input {
  padding: 1px 4px 0px 5px !important;
  border: 1px solid #76767694 !important;
}

.fetch-input .MuiOutlinedInput-root {
  padding: 0 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Add this CSS in your styles or create a separate stylesheet */

.tableHeader {
  background-color: #cff4fc;
}

.tableHeaderCell {
  padding: 8px;
}

.tabelrow {
  height: 36px;
  background-color: #f2f2f2; 
  text-align: center;/* Adjust the height as needed */
}

.tabelrow td {
  padding: 4px 8px; /* Adjust the padding as needed */
}
.Search{
  
  background-color:#e4e9f0;
}
.EditButton{
  background-color:#00d284;
}
/* Add more styles as needed */

.custom-header-class {
  font-weight: bold;
  color: #000000;
  font-size: 1rem;
  background-color: #cff4fc; /* Your desired color */
  /* Add more styling properties if needed */
}
.custom-row-class {
  
  background-color: #f2f2f2; /* Your desired color */
  /* Add more styling properties if needed */
}
